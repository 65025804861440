
import { defineComponent, reactive, toRefs, ref, watch } from 'vue';
import router from "@/router/index";
import { listJobApply, getHunterJob, updateJobApplyMatchState} from '@/util/apis'

export default defineComponent({
    name: "",
    setup() {
        const state = reactive({
            activeName: 'first',
            value2: '',
            radio1: 0,
            tableData: [] as any,
            totalCount: 0,
            currentPage: 1,
            pagesize: 10,
            psArr: [10, 20, 30],
            matchStateList: ['待反馈', '待定', '合适', '不合适'],
            jobInfo: {} as any,

        })
        watch(() => state.radio1, (newValue, oldValue) => {
            getJobApply()
        }
        )

        const handleClick = () => { }
        const handleEdit = (index: number, row: any) => {
            changeState({
                id: state.tableData[index].jobApplyId,
                matchState: '1'
            });
        }
        const handle1 = (index: number, row: any) => {
             changeState({
                id: state.tableData[index].jobApplyId,
                matchState: '2'
            });
        }
        const handle2 = (index: number, row: any) => {
             changeState({
                id: state.tableData[index].jobApplyId,
                matchState: '3'
            });
        }

        const changeState = (param:any) =>{
            updateJobApplyMatchState(param).then(res=>{
                getJobApply();
            })
        }
        
        const handleDelete = (index: number, row: any) => {
            console.log(index, row)
        }

        const handleSizeChange = (size: number) => {
            state.pagesize = size;
            getJobApply();
        }
        const handleCurrentChange = (currentPage: number) => {
            state.currentPage = currentPage;
            getJobApply();
        }
        const getJobApply = () => {
            listJobApply({
                page: state.currentPage,
                pageSize: state.pagesize,
                jobId: router.currentRoute.value.params.id,
                matchState: state.radio1
            }).then(res => {
                state.tableData = res.data.resumeList;
                state.totalCount = res.data.totalCount
            })
        }
        getJobApply();
        getHunterJob({
            id: router.currentRoute.value.params.id,
        }).then(res => {
            res.data.cusCity = res.data.ext.cityList.map((item: any) => item.remarks).join('、');
            state.jobInfo = res.data;
            console.log('state.jobInfo', state.jobInfo)
        })

        return {
            ...toRefs(state), handleClick, handleEdit, handleDelete, handleSizeChange, handleCurrentChange, handle1, handle2
        }
    }
})

<template>
    <div class="w100 bs text-14">
        <div class="jd_top w100 bs">
            <div class="w100 flex justify-between c_595959">
                <div>职位ID：{{ jobInfo.id }}</div>
                <div>
                    <!-- <span style="margin-right:24px;">
                        创建人：肖经理
                    </span> -->
                    <span class="css-10387yq e1q92e850">
                        更新时间：{{ jobInfo.updateDate }}
                    </span>
                </div>
            </div>
            <div class="w100 flex align-center" style="margin: 16px 0px;">
                <div class="flex align-center">
                    <h1 class="text-18 c_262626" style="font-weight: 600;">{{ jobInfo.name }}</h1>
                    <div class="text-18" style="color:#fa6046;margin-left: 16px;">
                        {{ jobInfo.salaryMin / 1000 }}-{{ jobInfo.salaryMax / 1000 }}K/月</div>
                    <div class="jd_zp_box"
                        style="border-color: rgb(146, 214, 255); background-color: rgb(230, 247, 255);"><span
                            style="color: rgb(24, 144, 255);">{{ jobInfo.state == '1' ? '招聘中' : (jobInfo.state ==
                                    '2' ? '已下线' : '待发布')
                            }}</span>
                    </div>
                </div>
            </div>
            <div class="c_595959">招聘企业：{{ jobInfo.ext ? jobInfo.ext.companyName : '' }}</div>
            <div class="jd_top_wr w100 bs">
                <div class="ant-row ant-row-space-between ant-row-middle">
                    <div class="ant-col flex align-center">
                        <span class="flex align-center" style="margin-right: 36px;"><span class="text-22 c_262626"
                                style="margin-right: 10px;">--</span>浏览量</span>
                        <span class="flex align-center" style="margin-right: 36px;"><span class="text-22 c_262626"
                                style="margin-right: 10px;">--</span>应聘量</span>
                        <span class="flex align-center"><span class="text-22 c_262626"
                                style="margin-right: 10px;">--</span>未处理</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="jd_con_wrap mt_10 w100 bgc_fff" style="padding-bottom:20px;">
            <div class="w100">
                <el-tabs v-model="activeName" class="jd_tabs bs shrink_0 w100" @tab-click="handleClick">
                    <el-tab-pane label="应聘简历" name="first">
                        <div class="jd_tab_con w100 bs">
                            <div class="w100 flex justify-between align-center" style="height: 56px;">
                                <div class="flex align-center">
                                    <div class="flex align-center">
                                        <div>应聘时间：</div>
                                        <el-date-picker v-model="value2" type="daterange" unlink-panels
                                            range-separator="To" start-placeholder="开始日期" end-placeholder="结束日期" />
                                    </div>
                                    <div class="flex align-center" style="margin-left: 12px;">
                                        <el-radio-group v-model="radio1" class="ml-4">
                                            <el-radio :label="index" size="large"
                                                v-for="(item, index) in matchStateList" :key="index">{{ item }}
                                            </el-radio>
                                        </el-radio-group>
                                    </div>
                                </div>
                                <div class="text-14">
                                    共 {{ totalCount }} 份简历
                                </div>
                            </div>
                            <div>
                                <el-table ref="multipleTableRef" header-cell-class-name="bgc_fa" :data="tableData"
                                    style="width: 100%">
                                    <el-table-column label="姓名" width="120">
                                        <template #default="scope">
                                            <div>{{ scope.row.name }}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column property="gender" label="性别" width="120" />
                                    <el-table-column property="age" label="年龄" width="120" />
                                    <el-table-column property="workYears" label="工作经验" width="120" />
                                    <el-table-column label="最近公司" width="120">
                                        <template #default="scope">
                                            <div>{{ scope.row.workList && scope.row.workList.length > 0 ?
                                                    scope.row.workList[0].company : ''
                                            }}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="最近职位" width="120">
                                        <template #default="scope">
                                            <div>{{ scope.row.workList && scope.row.workList.length > 0 ?
                                                    scope.row.workList[0].job : ''
                                            }}</div>
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column property="" label="简历投递时间" width="120" /> -->
                                    <el-table-column label="操作">
                                        <template #default="scope">
                                            <el-button size="small" v-if="!(radio1 == 1)"
                                                @click="handleEdit(scope.$index, scope.row)">待定</el-button>
                                            <el-button size="small" v-if="!(radio1 == 2)" type="primary"
                                                @click="handle1(scope.$index, scope.row)">合适</el-button>
                                            <el-button size="small" v-if="!(radio1 == 3)" type="danger"
                                                @click="handle2(scope.$index, scope.row)">不合适</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="mt_20 w100 bs flex justify-end">
                                    <el-pagination lazy layout="total ,prev,pager,next,sizes,jumper" :total="totalCount"
                                        @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                        :current-page="currentPage" :page-sizes="psArr" style="font-size: 20px">
                                    </el-pagination>

                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="职位详情" name="second">
                        <div class="w100 bs flex">
                            <div class="jd_zwxq_left">
                                <div class="css-12g7qn">
                                    <h2>职位信息</h2>
                                    <div style="flex: 1 1 0%;">
                                        <div class="css-5007or"></div>
                                    </div>
                                </div>
                                <div class="w100 bs c_595959 text-14 flex align-center"
                                    style="padding-left: 24px;position: relative;">
                                    <div>职能类别：--</div>
                                    <div class="css-8fmo6r">|</div>
                                    <div>
                                        薪资范围：{{ jobInfo.salaryMin / 1000 }}-{{ jobInfo.salaryMax / 1000 }}K/月
                                    </div>
                                    <div class="css-8fmo6r">|</div>
                                    <div>工作城市：{{ jobInfo.cusCity }}</div>
                                </div>
                                <div class="css-12g7qn">
                                    <h2>详细要求</h2>
                                    <div style="flex: 1 1 0%;">
                                        <div class="css-5007or"></div>
                                    </div>
                                </div>
                                <div class="w100 bs c_595959 text-14" style="padding-left: 24px;">
                                    <div class="flex align-center mb_20">
                                        <div>招聘人数：{{ jobInfo.jobCount }} 人</div>
                                        <div class="css-8fmo6r">|</div>
                                        <div>学历要求：--</div>
                                        <div class="css-8fmo6r">|</div>
                                        <div>工作经验：--</div>
                                    </div>
                                    <p class="mb_20">
                                        职位描述：{{ jobInfo.description }}
                                    </p>
                                </div>
                            </div>
                            <div class="jd_zwxq_right">

                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>

        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, ref, watch } from 'vue';
import router from "@/router/index";
import { listJobApply, getHunterJob, updateJobApplyMatchState} from '@/util/apis'

export default defineComponent({
    name: "",
    setup() {
        const state = reactive({
            activeName: 'first',
            value2: '',
            radio1: 0,
            tableData: [] as any,
            totalCount: 0,
            currentPage: 1,
            pagesize: 10,
            psArr: [10, 20, 30],
            matchStateList: ['待反馈', '待定', '合适', '不合适'],
            jobInfo: {} as any,

        })
        watch(() => state.radio1, (newValue, oldValue) => {
            getJobApply()
        }
        )

        const handleClick = () => { }
        const handleEdit = (index: number, row: any) => {
            changeState({
                id: state.tableData[index].jobApplyId,
                matchState: '1'
            });
        }
        const handle1 = (index: number, row: any) => {
             changeState({
                id: state.tableData[index].jobApplyId,
                matchState: '2'
            });
        }
        const handle2 = (index: number, row: any) => {
             changeState({
                id: state.tableData[index].jobApplyId,
                matchState: '3'
            });
        }

        const changeState = (param:any) =>{
            updateJobApplyMatchState(param).then(res=>{
                getJobApply();
            })
        }
        
        const handleDelete = (index: number, row: any) => {
            console.log(index, row)
        }

        const handleSizeChange = (size: number) => {
            state.pagesize = size;
            getJobApply();
        }
        const handleCurrentChange = (currentPage: number) => {
            state.currentPage = currentPage;
            getJobApply();
        }
        const getJobApply = () => {
            listJobApply({
                page: state.currentPage,
                pageSize: state.pagesize,
                jobId: router.currentRoute.value.params.id,
                matchState: state.radio1
            }).then(res => {
                state.tableData = res.data.resumeList;
                state.totalCount = res.data.totalCount
            })
        }
        getJobApply();
        getHunterJob({
            id: router.currentRoute.value.params.id,
        }).then(res => {
            res.data.cusCity = res.data.ext.cityList.map((item: any) => item.remarks).join('、');
            state.jobInfo = res.data;
            console.log('state.jobInfo', state.jobInfo)
        })

        return {
            ...toRefs(state), handleClick, handleEdit, handleDelete, handleSizeChange, handleCurrentChange, handle1, handle2
        }
    }
})
</script>
<style scoped>
@import "./index.css";
</style>